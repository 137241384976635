import React, {useEffect, useState} from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import TagManager from "react-gtm-module"

LicenseInfo.setLicenseKey( process.env.MUI_LICENSE_KEY );

import ReactGA from "react-ga4";

import { config } from '@fortawesome/fontawesome-svg-core';
import withRedux from 'next-redux-wrapper';
import { getCLS, getFID, getLCP, ReportHandler } from 'web-vitals';

import {useStore, wrapper} from '../redux/store'

import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;
import {Provider, useSelector} from 'react-redux'

import '../styles/globals.scss';
// import dynamic from "next/dynamic";

import "../styles/property.css"
import "react-loading-skeleton/dist/skeleton.css";
import Checkers from "../helpers/class/Checkers";
import {fetchBuilderSettingByKey} from "../helpers/MethodHelper";
import Layout from "../components/Layout";
import Header from "../components/Header";
import BottomSections from "../components/BottomSections";
import {Fancybox} from "@fancyapps/ui/dist/fancybox.esm";

declare global {
    interface Window {
        dataLayer: any;
        designMode: any;
    }
}

function MyApp ({ Component, pageProps }) {
    // const store = useStore( pageProps.initialReduxState )
    const {store, props} = wrapper.useWrappedStore(pageProps);

    const settings = store.getState().builderSettingsData.settings

    const pageId = store.getState().propertiesParams.page_id

    const servicesGoogleTagManagerId = settings != null ? settings.servicesGoogleTagManagerId : null

    useEffect(() => {
        if (Checkers.isValidString(servicesGoogleTagManagerId)) {
            TagManager.initialize({gtmId: servicesGoogleTagManagerId});
        }
    }, []);

    if (Checkers.isValidPositiveNumber(pageId) && pageId != 1427) {
        ReactGA.initialize("G-XELB2CP715");
    }

    const getLayout = Component.getLayout ?? ((page) => page)

    return (
        getLayout(
            <Provider store={store}>
                <Component {...props} />
            </Provider>
        )
        // <Layout>
        //     <Provider store={store}>
        //         <Header referer={urlHref} {...props} />
        //         <Component {...props} />
        //         <BottomSections {...props} />
        //     </Provider>
        // </Layout>

    )
}

export function reportWebVitals ( metric ) {
  window.dataLayer = window.dataLayer || [];
  function sendToGTM ( name, delta, id ) {
    // Assumes the global `dataLayer` array exists, see:
    // https://developers.google.com/tag-manager/devguide
    window.dataLayer.push({
      event: 'web-vitals',
      event_category: 'Web Vitals',
      event_action: name.name,
      // Google Analytics metrics must be integers, so the value is rounded.
      // For CLS the value is first multiplied by 1000 for greater precision
      // (note: increase the multiplier for greater precision if needed).
      event_value: Math.round( name.name === 'CLS' ? name.delta * 1000 : name.delta ),
      // The 'id' value will be unique to the current page load. When sending
      // multiple values from the same page (e.g. for CLS), Google Analytics can
      // compute a total by grouping on this ID (note: requires `eventLabel` to
      // be a dimension in your report).
      event_label: name.id,
    });
  }
  if ( metric.label == 'web-vital' ) {
    getCLS( sendToGTM as ReportHandler );
    getFID( sendToGTM as ReportHandler );
    getLCP( sendToGTM as ReportHandler );
  }
}

// export default MyApp;

// export default withRedux( useStore )( MyApp );
// export default wrapper.withRedux(MyApp)

export default MyApp

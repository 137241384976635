import cloneDeep from 'lodash/cloneDeep';
import MobileDetect from 'mobile-detect';

import {
  addCollection, addDestination, addLocation, addTag, addTheme,
  setAdults, setBedrooms,
  setCheckin,
  setCheckout,
  setInfants, setIsDesktop, setIsMobile, setIsTablet,
  setKids, setOffset, setPageNumber,
  setSearchText, updateDateRangeValid
} from '../../redux/actions';
import {updateBedrooms} from "../MethodHelper";

class Checkers {
  private props: any;

  static isDefined(variable) {
    return variable !== undefined;
  }

  static isValidNumber(number) {
    return !isNaN(number) && number != null;
  }

  static isValidLatitude(number) {
    return isFinite(number) && Math.abs(number) <= 90;
  }

  static isValidLongitude(number) {
    return isFinite(number) && Math.abs(number) <= 180;
  }

  static isValidPositiveNumber(number) {
    return !isNaN(number) && number != null && number > 0;
  }

  static isValidString(string) {
    return string != null && typeof string === "string" && string.length > 0;
  }

  static isValidObj(obj, key) {
    return obj.hasOwnProperty(key) && typeof obj[key] === 'object' && obj[key] !== null;
  }

  static isValidObjPositiveInteger(obj, key) {
    return obj.hasOwnProperty(key) && this.isValidPositiveNumber(obj[key]);
  }

  static isValidObjString(obj, key, log = false) {
    return obj.hasOwnProperty(key) && typeof obj[key] === "string" && obj[key].length > 0;
  }

  static isValidBoolean(boolean) {
    return typeof boolean === "boolean";
  }

  static booleanState(boolean) {
    return this.isValidBoolean(boolean) && boolean;
  }

  static valueToBoolean(value) {
    return value === 1 || value === '1' || value === true;
  }

  static isValidArray(array) {
    return Array.isArray(array) && array.length > 0
  }

  static isValidArrayOfString(array) {
    let valid = Array.isArray(array) && array.length > 0

    for(let i=0; i<array.length; i++){
      if ( !Checkers.isValidString(array[i])) {
        valid = false
        break;
      }
    }

    return valid
  }

  static isValidArrayKeysNotNull(array) {
    for (const arrayEl of array) {
      if (!(Array.isArray(array) && array.length > 0 && this.isValidString(arrayEl))) {
        return false;
      }
    }
    return true;
  }

  static objChildExists(object, child) {
    return object.hasOwnProperty(child)
  }

  static objChildExistsAndIsValidArray(object, child) {
    return object.hasOwnProperty(child) && this.isValidArray(object[child]);
  }

  static serviceImagesValid(obj) {
    return this.objChildExists(obj, 'service_images') &&
        this.isValidArray(obj.service_images) &&
        this.isValidString(obj.service_images[0].service_image);
  }

  static googleMapsPropertyInit(googleMapApiKey, googleMapShow, lat, lng) {
    return (this.isValidBoolean(googleMapShow) && googleMapShow) &&
        this.isValidString(googleMapApiKey) &&
        this.isValidNumber(lat) &&
        this.isValidNumber(lng);
  }

  static displaySpaces(spaces_obj, areasLayoutSetting) {
    return areasLayoutSetting=='thumbnails' || this.isValidPositiveNumber(spaces_obj.areaSqm) || this.isValidPositiveNumber(spaces_obj.areaOutdoorSqm) ||
        (this.objChildExists(spaces_obj, 'spaces') && this.isValidArray(spaces_obj.spaces));
  }

  static masonryColumns(arr) {
    return this.isValidArray(arr) &&
    (arr.length > 2) ? 3 : (arr.length > 1) ? 2 : (arr.length > 0) && 1;
  }

  static serialize(obj) {
    let str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  static fetchQueryParamateres(store, query) {
    let search = String(query.search);
    if (search != 'undefined' && search != 'null' && search != undefined && search != null && search.length > 0) {
      store.dispatch(setSearchText(search))
    }

    let collections = String(query.collections);
    if (collections != 'null' && collections != 'undefined' && collections != undefined && collections.length > 0) {
      store.dispatch(addCollection(collections))
    }

    let destinations = String(query.destinations);
    if (destinations != 'null' && destinations != 'undefined' && destinations != undefined && destinations.length > 0) {
      store.dispatch(addDestination(destinations))
    }

    let locations = String(query.locations);
    if (locations != 'null' && locations != 'undefined' && locations != undefined && locations.length > 0) {
      store.dispatch(addLocation(locations))
    }

    let themes = String(query.themes);
    if (themes != 'null' && themes != 'undefined' && themes != undefined && themes.length > 0) {
      store.dispatch(addTheme(themes))
    }

    let tags = String(query.tags);
    if (tags != 'null' && tags != 'undefined' && tags != undefined && tags.length > 0) {
      store.dispatch(addTag(tags))
    }

    let checkin = query.checkin;
    if (checkin != 'null' && checkin != null) {
      store.dispatch(setCheckin(checkin))
    }

    let checkout = query.checkout;
    if (checkout != 'null' && checkout != null) {
      store.dispatch(setCheckout(checkout))
    }

    if (Checkers.isValidDate(checkout) && Checkers.isValidDate(checkin)) {
      store.dispatch( updateDateRangeValid(true))
    }

    let bedrooms = query.bedrooms;
    if (bedrooms != 'null' && bedrooms != null) {
      store.dispatch(setBedrooms(bedrooms))
    }

    let adults = query.adults;
    if (adults != 'null' && adults != null) {
      store.dispatch(setAdults(adults))
    }

    let infants = query.infants;
    if (infants != 'null' && infants != null) {
      store.dispatch(setInfants(infants))
    }

    let kids = query.kids;
    if (kids != 'null' && kids != null) {
      store.dispatch(setKids(kids))
    }

    let pageNumber = query.page;
    if (pageNumber != 'null' && pageNumber != null && pageNumber>1) {
      const offset = (pageNumber - 1) * 24;
      store.dispatch(setOffset(offset))
      store.dispatch(setPageNumber(parseInt(pageNumber)))
    }
  }

  static getPropertiesParams(store) {
    return cloneDeep(store.getState().propertiesParams)
  }

  static getUserAgentData(store, request) {
    const md = new MobileDetect(request.headers['user-agent'])

    const isMobile = md.phone() !== null || md.mobile() === 'UnknownMobile'
    const isTablet = md.tablet() !== null || md.mobile() === 'UnknownTablet'
    const isDesktop = !isMobile && !isTablet;

    store.dispatch(setIsDesktop(isDesktop ? 1 : 0))
    store.dispatch(setIsTablet(isTablet ? 1 : 0))
    store.dispatch(setIsMobile(isMobile ? 1 : 0))

    return [isMobile, isTablet, isDesktop]
  }

  static isValidDate(date: string) {
    if(date==null || date==undefined) return false;
    let regEx = /^\d{4}-\d{2}-\d{2}$/;
    if(!date.match(regEx)) return false;  // Invalid format
    let d = new Date(date);
    let dNum = d.getTime();
    if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0,10) === date;

    // @ts-ignore
    // return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));

    // @ts-ignore
    // return !isNaN(new Date(date));
  }

  static getRangeFromAvailabilityObject(range, startDate, endDate) {
    let subRange = [];

    Object.keys(range).forEach( (key, index) => {
      console.log("debugLabel", "key", key, "index", index, "range[item]", range[key])
    })

    return;
  }

  static checkUrlIsImage(url) {
    return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }

  static isHtml(str) {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType == 1) return true;
    }

    return false;
  }

  static isHexColor (hex) {
    if (hex==null) {
      return false
    }
    if (hex===undefined) {
      return false
    }
    if (!this.isValidString(hex)) {
      return false
    }
    if (hex.length==0) {
      return false
    }
    if (hex.length==1) {
      return false
    }

    hex = hex.replace("#", "")
    return typeof hex === 'string'
        && hex.length === 6
        && !isNaN(Number('0x' + hex))
  }
}

export default Checkers;

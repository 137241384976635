import Checkers from "./Checkers";

class Methods {
  static floatFormat ( float, decimals ) {
    return Math.trunc( float*Math.pow( 10, decimals ) )/Math.pow( 10, decimals );
  }
  static htmlTagReplacer ( item, tag, newTag ) {
    const tagCount = item.search( `<${tag}>` );
    if ( tagCount > 0 ) {
      for ( let i=0; i < tagCount; i++ ) {
        item = item.replace( `<${tag}>`, `<${newTag}>` ).replace( `</${tag}>`, `</${newTag}>` )
      }
    }
    return item;
  }

  static addParametersToPropertyUrl(checkin?: string, checkout?: string, adults?: number, kids?: number, infants?: number){
    let response = {
      checkin: null,
      checkout: null,
      adults: null,
      kids: null,
      infants: null
    };

    if(Checkers.isValidDate(checkin)){
      response.checkin = checkin
    }

    if(Checkers.isValidDate(checkout)){
      response.checkout = checkout
    }

    if(Checkers.isValidNumber(adults)){
      response.adults = adults
    }

    if(Checkers.isValidNumber(kids)){
      response.kids = kids
    }

    if(Checkers.isValidNumber(infants)){
      response.infants = infants
    }

    Object.keys(response).forEach(key => {
      if (response[key] === null || response[key]==0) {
        delete response[key];
      }
    });

    const queryParams = new URLSearchParams(response).toString();

    return queryParams.length>0? "?"+queryParams : ""
  }

  static scrollToTop(searchBarDivHeight = null) {
    let barOffset = 0;
    const element = document.getElementById('properties');
    if (window.innerWidth > 768 && searchBarDivHeight!=null) {
      barOffset = searchBarDivHeight;
    }

    const scrollToYOffset = element.getBoundingClientRect().y + window.scrollY - barOffset;

    // await scroll.scrollTo(scrollToYOffset);
    // scroll.scrollTo({ top:scrollToYOffset, behavior: "instant"})

    // @ts-ignore
    window.scrollTo({ top:scrollToYOffset, behavior: "instant"})
  }
}

export default Methods;

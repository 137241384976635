import { HYDRATE } from 'next-redux-wrapper';
import { combineReducers } from 'redux'

import {setIsListingPage, updateBookingFormTriggered, updateCaptchaSiteKey} from './actions';
import Checkers from "../helpers/class/Checkers";
import dayjs from "dayjs";

const propertiesParams = {
  isListingPage: false,
  isSearchPage: false,
  property_id: null,
  page_id: 0,
  limit: 24,
  offset: 0,
  pageNumber: 1,
  destinations: [],
  tags: [],
  themes: [],
  locations: [],
  statuses: [3],
  paramsApplied: 0,
  collections: [],
  gtypes: [],
  types: [1,2],
  search: null,
  checkin: null,
  checkout: null,
  loadingProperties: false,
  bedrooms: 0,
  adults: 0,
  kids: 0,
  infants: 0,
  dateRangeValid: false,
  priceNeedUpdate: true,
  hideUnavailableProperties: false,
  searchWithMap: false,
  latTop: null,
  latBottom: null,
  lngLeft: null,
  lngRight: null,
  whDirectCheckout: false
}

// PROPERTIES PARAMS
const propertiesParamsReducer = ( state = propertiesParams, action ) => {
  let type = action.type;
  let value = action.value;

  switch (type) {
    case HYDRATE:
      return action.payload.propertiesParams;
    case 'setPageId': {
      return {
        ...state,
        page_id: value
      }
    }
    case 'updateSearchWithMap': {
      return {
        ...state,
        searchWithMap: value
      }
    }
    case 'updatePriceNeedUpdate': {
      return {
        ...state,
        priceNeedUpdate: value
      }
    }
    case 'updateMapTopLeft': {
      console.log("forDebug", "propertiesParams[redux]", value)
      return {
        ...state,
        latTop: value
      }
    }
    case 'updateMapBountries': {
      console.log("forDebug", "updateMapBountries", action)

      return {
        ...state,
        latTop: action.latTop,
        latBottom: action.latBottom,
        lngLeft: action.lngLeft,
        lngRight: action.lngRight
      }
    }
    case 'updateHideUnavailableProperties': {
      return {
        ...state,
        hideUnavailableProperties: value
      }
    }
    case 'updateWhDirectCheckout': {
      return {
        ...state,
        whDirectCheckout: value
      }
    }
    case 'setPropertyId': {
      return {
        ...state,
        property_id: value
      }
    }
    case 'setSearchText': {
      return {
        ...state,
        search: value
      }
    }
    case 'setOffset': {
      return {
        ...state,
        offset: value
      }
    }
    case 'setPageNumber': {
      return {
        ...state,
        pageNumber: value
      }
    }
    case 'removeStatusId': {
      return {
        ...state,
        statuses: state.statuses.filter(item => item !== value)
      }
    }
    case 'addStatusId': {
      return {
        ...state,
        statuses: [...state.statuses, value]
      }
    }
    case 'applyParams': {
      return {
        ...state,
        paramsApplied: value
      }
    }
    case 'removeThemeId': {
      return {
        ...state,
        themes: state.themes.filter(item => item !== value)
      }
    }
    case 'setIsListingPage': {
      return {
        ...state,
        isListingPage: value
      }
    }
    case 'setIsSearchPage': {
      return {
        ...state,
        isSearchPage: value
      }
    }
    case 'setLoadingProperties': {
      return {
        ...state,
        loadingProperties: value
      }
    }
    case 'addThemeId': {
      return {
        ...state,
        themes: [...state.themes, value]
      }
    }
    case 'removeTagId': {
      return {
        ...state,
        tags: state.tags.filter(item => item !== value)
      }
    }
    case 'addTagId': {
      return {
        ...state,
        tags: [...state.tags, value]
      }
    }
    case 'removeCollectionId': {
      return {
        ...state,
        collections: state.collections.filter(item => item !== value)
      }
    }
    case 'addCollectionId': {
      return {
        ...state,
        collections: [...state.collections, value]
      }
    }
    case 'removeTypeId': {
      return {
        ...state,
        gtypes: state.gtypes.filter(item => item !== value)
      }
    }
    case 'addTypeId': {
      return {
        ...state,
        gtypes: [...state.gtypes, value]
      }
    }
    case 'setSystemTypes': {
      return {
        ...state,
        types: value
      }
    }
    case 'removeLocationId': {
      return {
        ...state,
        locations: state.locations.filter(item => item !== value)
      }
    }
    case 'addLocationId': {
      return {
        ...state,
        locations: [...state.locations, value]
      }
    }
    case 'increaseInfants': {
      return {
        ...state,
        infants: state.infants + 1,
        priceNeedUpdate: true
      }
    }
    case 'increaseAdults': {
      return {
        ...state,
        adults: state.adults + 1,
        priceNeedUpdate: true
      }
    }
    case 'decreaseInfants': {
      return {
        ...state,
        infants: state.infants - 1,
        priceNeedUpdate: true
      }
    }
    case 'decreaseAdults': {
      return {
        ...state,
        adults: state.adults - 1,
        priceNeedUpdate: true
      }
    }
    case 'increaseBedrooms': {
      return {
        ...state,
        bedrooms: state.bedrooms + 1,
        priceNeedUpdate: true
      }
    }
    case 'decreaseBedrooms': {
      return {
        ...state,
        bedrooms: state.bedrooms - 1,
        priceNeedUpdate: true
      }
    }
    case 'increaseKids': {
      return {
        ...state,
        kids: state.kids + 1,
        priceNeedUpdate: true
      }
    }
    case 'decreaseKids': {
      return {
        ...state,
        kids: state.kids - 1,
        priceNeedUpdate: true
      }
    }
    case 'setAdults': {
      return {
        ...state,
        adults: parseInt(value),
        priceNeedUpdate: true
      }
    }
    case 'setBedrooms': {
      return {
        ...state,
        bedrooms: parseInt(value),
        priceNeedUpdate: true
      }
    }
    case 'setKids': {
      return {
        ...state,
        kids: parseInt(value),
        priceNeedUpdate: true
      }
    }
    case 'setInfants': {
      return {
        ...state,
        infants: parseInt(value),
        priceNeedUpdate: true
      }
    }
    case 'removeDestinationId': {
      return {
        ...state,
        destinations: state.destinations.filter(item => item !== value)
      }
    }
    case 'setDateRange': {
      // let unix_timestamp_checkin = Date.parse( value[0])
      // let date_checkin = new Date( unix_timestamp_checkin ) instanceof Date ? new Date( unix_timestamp_checkin ) : null ;
      // let checkin = date_checkin.toLocaleDateString( 'en-CA' );
      let dateRangeValid = true
      let checkin = dayjs(value[0]).format('YYYY-MM-DD')
      if (!Checkers.isValidDate(checkin)) {
        checkin = null
        dateRangeValid = false
      }

      // let unix_timestamp_checkout = Date.parse( value[1])
      // let date_checkout = new Date( unix_timestamp_checkout ) instanceof Date ? new Date( unix_timestamp_checkout ) : null ;
      // let checkout = date_checkout.toLocaleDateString( 'en-CA' );
      let checkout = dayjs(value[1]).format('YYYY-MM-DD')
      if (!Checkers.isValidDate(checkout)) {
        checkout = null
        dateRangeValid = false
      }

      return {
        ...state,
        checkin: checkin,
        checkout: checkout,
        dateRangeValid: dateRangeValid,
        priceNeedUpdate: true
      }
    }
    case 'setCheckout': {
      return {
        ...state,
        checkout: value
      }
    }
    case 'updateDateRangeValid': {
      return {
        ...state,
        dateRangeValid: value
      }
    }
    case 'setCheckin': {
      return {
        ...state,
        checkin: value
      }
    }
    case 'setDestination': {
      return {
        ...state,
        destinations: [value]
      }
    }
    case 'removeAllDestination': {
      return {
        ...state,
        destinations: []
      }
    }
    case 'addDestinationId': {
      if (state.destinations === undefined || state.destinations.length == 0) {
        return {
          ...state,
          destinations: [value]
        }
      } else {
        return {
          ...state,
          destinations: [...state.destinations, value]
        }
      }
    }
    case 'FETCH_STATE': {
      return {
        ...state,
      }
    }
    default:
      return state
  }
}

const propertiesFilters = {
  filters: []
}

const propertiesFiltersReducer = ( state = propertiesFilters, action ) => {
  let type = action.type;
  let page = action.page;
  let value = action.value;

  switch ( type ) {
  case HYDRATE:
    return action.payload.propertiesFiltersData;
  case 'fetchFilters': {
    return action.data
  }
  case 'setPropertiesFiltersData': {
    return value
  }
  case 'countPropertiesForFilters': {
    return action.data
  }
  case 'FETCH_STATE': {
    return {
      ...state,
    }
  }
  default:
    return state
  }

}

const propertiesInitialStatus = {
  properties: []
}

const propertiesReducer = ( state = propertiesInitialStatus, action ) => {
  let type = action.type;
  let page = action.page;
  let value = action.value;

  switch ( type ) {
  case HYDRATE:
    return action.payload.propertiesData;
  case 'fetchProperties': {
    return action.data
  }
  case 'setPropertiesData': {
    return value
  }
  case 'FETCH_STATE': {
    return {
      ...state,
    }
  }
  default:
    return state
  }
}

const appSettings = {
  isPublicDomain: 0,
  captchaSiteKey: 0,
  isBookingsActive: null,
  capacityMax: null,
  hasWebhotelier: null,
  isOpenCalendar: null,
  bookingType: null,
  bookUrl: null,
  inquiriesOnly: 0,
  roomCode: null,
  propertySlug: null,
  hidePrices: null,
  isMobile: 0,
  isTablet: 0,
  isDesktop: 0,
  isHomepage: 0,
  isExperiencesPage: 0,
  bookingFormTriggered: false,
  price: null,
  finalPrice: null,
  discount: null,
  hasDiscount: false,
  accommondationLength: null,
  securityDeposit: null,
  priceLoading: false,
  searchSpinner: false,
  bookingBedroomId: null,
  bookingAppUrl: process.env.SCHEME + process.env.BOOKING_APP,
  minBedrooms: null,
  maxBedrooms: null,
  currentDestination: null
}

const appSettingsReducer = ( state = appSettings, action ) => {
  let type = action.type;
  let value = action.value;

  switch ( type ) {
  case HYDRATE:
    return action.payload.appSettings;
  case 'isMobile': {
    return {
      ...state,
      isMobile: value
    }
  }
  case 'updatePriceInfo': {
    return {
      ...state,
      price: value
    }
  }
  case 'setCapacityMax': {
    return {
      ...state,
      capacityMax: value
    }
  }
  case 'setMinBedrooms': {
    return {
      ...state,
      minBedrooms: value
    }
  }
  case 'setMaxBedrooms': {
    return {
      ...state,
      maxBedrooms: value
    }
  }
  case 'updateCaptchaSiteKey': {
    return {
      ...state,
      captchaSiteKey: value
    }
  }
  case 'setIsHomePage': {
    return {
      ...state,
      isHomepage: value
    }
  }
  case 'setIsExperiencesPage': {
    return {
      ...state,
      isExperiencesPage: value
    }
  }
  case 'updateBookingFormTriggered': {
    return {
      ...state,
      bookingFormTriggered: value
    }
  }
  case 'setSearchSpinner': {
    return {
      ...state,
      searchSpinner: value
    }
  }
  case 'updateFinalPriceInfo': {
    return {
      ...state,
      finalPrice: value
    }
  }
  case 'updateDiscountInfo': {
    return {
      ...state,
      discount: value
    }
  }
  case 'updateHasDiscountInfo': {
    return {
      ...state,
      hasDiscount: value
    }
  }
  case 'updateAccommondationLength': {
    return {
      ...state,
      accommondationLength: value
    }
  }
  case 'updateSecurityDeposit': {
    return {
      ...state,
      securityDeposit: value
    }
  }
  case 'updatePriceLoading': {
    return {
      ...state,
      priceLoading: value
    }
  }
  case 'isTablet': {
    return {
      ...state,
      isTablet: value
    }
  }
  case 'isDektop': {
    return {
      ...state,
      isDesktop: value
    }
  }
  case 'isPublicDomain': {
    return {
      ...state,
      isPublicDomain: value
    }
  }
  case 'isBookingsActive': {
    return {
      ...state,
      isBookingsActive: value
    }
  }
  case 'hasWebhotelier': {
    return {
      ...state,
      hasWebhotelier: value
    }
  }
  case 'isOpenCalendar': {
    return {
      ...state,
      isOpenCalendar: value
    }
  }
  case 'bookingType': {
    return {
      ...state,
      bookingType: value
    }
  }
  case 'bookUrl': {
    return {
      ...state,
      bookUrl: value
    }
  }
  case 'bookingBedroomId': {
    return {
      ...state,
      bookingBedroomId: value
    }
  }
  case 'inquiriesOnly': {
    return {
      ...state,
      inquiriesOnly: value
    }
  }
  case 'roomCode': {
    return {
      ...state,
      roomCode: value
    }
  }
  case 'propertySlug': {
    return {
      ...state,
      propertySlug: value
    }
  }
  case 'hidePrices': {
    return {
      ...state,
      hidePrices: value
    }
  }
  case 'setCurrentDestination': {
    return {
      ...state,
      currentDestination: value
    }
  }
  case 'FETCH_STATE': {
    return {
      ...state,
    }
  }
  default:
    return state
  }

}

const propertyAvailabilityData = {
  initialAvailability: false,
  checkin: null,
  checkout: null
}

const propertyAvailabilityReducer = ( state = propertyAvailabilityData, action ) => {
  let type = action.type;
  let value = action.value;

  switch (type) {
    case HYDRATE: {
      return action.payload.propertyAvailability;
    }
    case 'updatepropertyAvailability': {
      return {
        ...state,
        checkin: action.data
      }
    }
    case 'updatepropertyAvailabilityForCheckout': {
      return {
        ...state,
        checkout: action.data
      }
    }
    case 'updatePropertyInitialAvailability': {
      return {
        ...state,
        initialAvailability: value
      }
    }
    default:
      return state
  }
}

const builderSettingsData = {
  settings: null
}

const builderSettingsReducer = ( state = builderSettingsData, action ) => {
  let type = action.type;
  let value = action.value;

  switch (type) {
    case HYDRATE: {
      return action.payload.builderSettingsData;
    }
    case 'setBuilderSettingsData': {
      return {
        ...state,
        settings: action.data
      }
    }
    case 'setMapAllProperties': {
      return {
        ...state,
        builderSettingsData: {
          ...builderSettingsData,
          builder_propertylist_map_all_properties: value
        }
      }
    }
    default:
      return state
  }
}

const singlePropertyData = {
  loading: true,
  pools: null,
  bedrooms: null,
  spaces: null,
  modal: {
    data: null,
    valid: false
  },
  modalBedrooms: {
    data: null,
    valid: false
  },
  modalSingleBedroom: {
    data: null,
    valid: false
  }
}

const singlePropertyReducer = ( state = singlePropertyData, action ) => {
  let type = action.type;

  switch (type) {
    case HYDRATE: {
      return action.payload.singlePropertyData;
    }
    case 'updatePools': {
      return {
        ...state,
        pools: action.data
      }
    }
    case 'updateLoading': {
      return {
        ...state,
        loading: action.data
      }
    }
    case 'updateSpaces': {
      return {
        ...state,
        spaces: action.data
      }
    }
    case 'updateBedrooms': {
      return {
        ...state,
        bedrooms: action.data
      }
    }
    case 'updateModalData': {
      return {
        ...state,
        modal: {
          data: action.data,
          valid: action.isValid
        }
      }
    }
    case 'updateModalBedrooms': {
      return {
        ...state,
        modalBedrooms: {
          data: action.data,
          valid: action.isValid
        }
      }
    }
    case 'updateModalSingleBedroom': {
      return {
        ...state,
        modalSingleBedroom: {
          data: action.data,
          valid: action.isValid
        }
      }
    }
    default:
      return state
  }
}

const uiData = {
  searchBarDivHeight: null,
  mainPacer: false
}

const uiDataReducer = ( state = uiData, action ) => {
  let type = action.type;

  switch (type) {
    case HYDRATE: {
      return action.payload.uiData;
    }
    case 'setUiSearchBarDivHeight': {
      return {
        ...state,
        searchBarDivHeight: action.value
      }
    }
    case 'updateMainPacer': {
      return {
        ...state,
        mainPacer: action.value
      }
    }
    default:
      return state
  }
}

const singlePropertyPriceData = {
  price : null,
  finalPrice : null,
  discount : null,
  hasDiscount : null,
  accommondationLength : null,
  securityDeposit : null
}

const singlePropertyPriceDataReducer = ( state = singlePropertyPriceData, action ) => {
  let type = action.type;

  switch (type) {
    case HYDRATE: {
      return action.payload.uiData;
    }
    case 'setPrice': {
      return {
        ...state,
        price: action.value
      }
    }
    case 'setFinalPrice': {
      return {
        ...state,
        finalPrice: action.value
      }
    }
    case 'setDiscount': {
      return {
        ...state,
        discount: action.value
      }
    }
    case 'setHasDiscount': {
      return {
        ...state,
        hasDiscount: action.value
      }
    }
    case 'setAccommondationLength': {
      return {
        ...state,
        accommondationLength: action.value
      }
    }
    case 'setSecurityDeposit': {
      return {
        ...state,
        securityDeposit: action.value
      }
    }
    default:
      return state
  }
}

const reducers = {
  propertiesParams: propertiesParamsReducer,
  propertiesData: propertiesReducer,
  propertiesFiltersData: propertiesFiltersReducer,
  appSettings: appSettingsReducer,
  propertyAvailability: propertyAvailabilityReducer,
  builderSettingsData: builderSettingsReducer,
  singlePropertyData: singlePropertyReducer,
  uiData: uiDataReducer,
  singlePropertyPriceData: singlePropertyPriceDataReducer
}

export default combineReducers( reducers )
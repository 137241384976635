import { useMemo } from 'react'
import { createWrapper } from 'next-redux-wrapper';
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

import reducers from './reducers'

let store

function initStore ( initialState ) {
  return createStore(
    reducers,
    initialState,
    // composeWithDevTools( applyMiddleware( thunkMiddleware ) )
    applyMiddleware( thunkMiddleware )
  )
  // createStore( reducers, initialState, applyMiddleware( thunkMiddleware ) )
}

export const initializeStore = ( preloadedState ) => {
  let _store = store ?? initStore( preloadedState )

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if ( preloadedState && store ) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    })
    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if ( typeof window === 'undefined' ) return _store
  // Create the store once in the client
  if ( !store ) store = _store

  return _store
}

export function useStore ( initialState ) {
  // return useMemo( () => initializeStore( initialState ), [initialState])
  return initializeStore( initialState )
  // return store
}

// export const makeStore = ( context ) =>
//   createStore( reducer, applyMiddleware( logger ) );

export const wrapper = createWrapper( initStore, { debug: false });
import {
  getCountPropertiesForFilters,
  getProperties,
  getPropertiesFilters,
  getPropertyAreas,
  getPropertyAvailability,
  getPropertyBedroomById,
  getPropertyBedrooms,
  getPropertyPools,
  getPropertyPrice,
  getPropertySpaces
} from '../api/client';
import Methods from "../helpers/class/Methods";

export const setPageId = ( val ) => ({ type: 'setPageId', value: val })

export const setIsHomePage = ( val ) => ({ type: "setIsHomePage", value: val })

export const setIsExperiencesPage = ( val ) => ({ type: "setIsExperiencesPage", value: val })

export const updateHideUnavailableProperties = ( val ) => ({ type: 'updateHideUnavailableProperties', value: val })

export const updateCaptchaSiteKey = ( val ) => ({ type: 'updateCaptchaSiteKey', value: val })

export const setMinBedrooms = ( val ) => ({ type: 'setMinBedrooms', value: val })

export const setMaxBedrooms = ( val ) => ({ type: 'setMaxBedrooms', value: val })

export const updateSearchWithMap = ( val ) => ({ type: 'updateSearchWithMap', value: val })

export const updateMapBountries = ( latTopVal, latBottomVal, lngLeftVal, lngRightVal ) => (
    {
      type: 'updateMapBountries', latTop: latTopVal, latBottom: latBottomVal, lngLeft: lngLeftVal, lngRight: lngRightVal
    }
)

export const fetchState = () => ({type: 'FETCH_STATE'})

export const updateMapTopLeft = ( val ) => ({ type: "updateMapTopLeft", value: val})

export const updateWhDirectCheckout = ( val ) => ({ type: "updateWhDirectCheckout", value: val})

export const setPropertyId = ( val ) => ({ type: 'setPropertyId', value: val })

export const setPropertySlug = ( val = '' ) => ({ type: 'propertySlug', value: val })

export const addStatus = ( val = -1 ) => ({ type: 'addStatusId', value: val })

export const removeStatus = ( val = -1 ) => ({ type: 'removeStatusId', value: val })

export const addDestination = ( val ) => ({ type: 'addDestinationId', value: val })

export const setDestination = ( val ) => ({ type: 'setDestination', value: val })

export const removeAllDestination = () => ({ type: 'removeAllDestination' })

export const removeDestination = ( val ) => ({ type: 'removeDestinationId', value: val })

export const addTheme = ( val ) => ({ type: 'addThemeId', value: val })

export const setSystemTypes = ( val ) => ({ type: 'setSystemTypes', value: val })

export const removeTheme = ( val ) => ({ type: 'removeThemeId', value: val })

export const addTag = ( val ) => ({ type: 'addTagId', value: val })

export const removeTag = ( val ) => ({ type: 'removeTagId', value: val })

export const addCollection = ( val ) => ({ type: 'addCollectionId', value: val })

export const removeCollection = ( val ) => ({ type: 'removeCollectionId', value: val })

export const addType = ( val ) => ({ type: 'addTypeId', value: val })

export const removeType = ( val ) => ({ type: 'removeTypeId', value: val })

export const addLocation = ( val ) => ({ type: 'addLocationId', value: val })

export const removeLocation = ( val ) => ({ type: 'removeLocationId', value: val })

export const setSearchText = ( val = '' ) => ({ type: 'setSearchText', value: val })

export const setOffset = ( val = 0 ) => ({ type: 'setOffset', value: val })

export const setPageNumber = ( val = 0 ) => ({ type: 'setPageNumber', value: val })

export const setCurrentDestination = ( val = 0 ) => ({ type: 'setCurrentDestination', value: val })

export const setIsMobile = ( val = 0 ) => ({ type: 'isMobile', value: val })

export const setIsTablet = ( val = 0 ) => ({ type: 'isTablet', value: val })

export const setIsDesktop = ( val = 0 ) => ({ type: 'isDektop', value: val })

export const setIsPublicDomain = ( val = 0 ) => ({ type: 'isPublicDomain', value: val })

export const setIsBookingsActive = ( val = 0 ) => ({ type: 'isBookingsActive', value: val })

export const setCapacityMax  = ( val = 0 ) => ({ type: 'setCapacityMax', value: val })

export const setHasWebhotelier = ( val = 0 ) => ({ type: 'hasWebhotelier', value: val })

export const setBookingType = ( val = 0 ) => ({ type: 'bookingType', value: val })

export const setHidePrices = ( val = 0 ) => ({ type: 'hidePrices', value: val })

export const setRoomCode = ( val = 0 ) => ({ type: 'roomCode', value: val })

export const setBookUrl = ( val = 0 ) => ({ type: 'bookUrl', value: val })

export const setBookingBedroomId = ( val = 0 ) => ({ type: 'bookingBedroomId', value: val })

export const setInquiriesOnly = ( val = 0 ) => ({ type: 'inquiriesOnly', value: val })

export const setIsOpenCalendar = ( val = 0 ) => ({ type: 'isOpenCalendar', value: val })

export const setApplyParams = ( val = 0 ) => ({ type: 'applyParams', value: val })

export const setPropertiesData = ( val = []) => ({ type: 'setPropertiesData', value: val })

export const setBuilderSettingsData = ( val = []) => ({ type: 'setBuilderSettingsData', data: val })

export const setDateRange = ( val ) => ({ type: 'setDateRange', value: val })

export const setCheckin = ( val ) => ({ type: 'setCheckin', value: val })

export const setCheckout = ( val ) => ({ type: 'setCheckout', value: val })

export const updateBookingFormTriggered = ( val ) => ({ type: "updateBookingFormTriggered", value: val })

export const setIsListingPage = ( val ) => ({ type: 'setIsListingPage', value: val })

export const setIsSearchPage = ( val ) => ({ type: 'setIsSearchPage', value: val })

export const setLoadingProperties = ( val ) => ({ type: 'setLoadingProperties', value: val })

export const increaseAdults = () => ({ type: 'increaseAdults' })

export const increaseInfants = () => ({ type: 'increaseInfants' })

export const decreaseAdults = () => ({ type: 'decreaseAdults' })

export const decreaseInfants = () => ({ type: 'decreaseInfants' })

export const increaseKids = () => ({ type: 'increaseKids' })

export const decreaseKids = () => ({ type: 'decreaseKids' })

export const increaseBedrooms = () => ({ type: 'increaseBedrooms' })

export const decreaseBedrooms = () => ({ type: 'decreaseBedrooms' })

export const setAdults = ( val ) => ({ type: 'setAdults', value: val  })

export const setBedrooms = ( val ) => ({ type: 'setBedrooms', value: val  })

export const setKids = ( val ) => ({ type: 'setKids', value: val  })

export const setInfants = ( val ) => ({ type: 'setInfants', value: val  })

export const setSearchSpinner = ( val ) => ({ type: 'setSearchSpinner', value: val  })

export const updatepropertyAvailability = ( data ) => ({ type: "updatepropertyAvailability", data: data })

export const updatepropertyAvailabilityForCheckout = ( data ) => ({ type: "updatepropertyAvailabilityForCheckout", data: data })

export const updatePropertyInitialAvailability = ( val ) => ({ type: "updatePropertyInitialAvailability", value: val })

export const setUiSearchBarDivHeight = ( val ) => ({ type: "setUiSearchBarDivHeight", value: val })

export const updateMainPacer = ( val ) => ({ type: "updateMainPacer", value: val })

export const updateDateRangeValid = ( val ) => ({ type: "updateDateRangeValid", value: val })

export const updatePriceNeedUpdate = ( val ) => ({ type: "updatePriceNeedUpdate", value: val })

export const fetchPropertiesAction = ( params, offset = null, searchBarDivHeight = 0) => async ( dispatch )  => {
  if(offset!=null) {
    params.offset = offset
  }

  dispatch({type: 'setLoadingProperties', value: true})
  if(searchBarDivHeight!=0) {
    Methods.scrollToTop(searchBarDivHeight)
  }

  const properties = await retrieveProperties( params ).then( res => {
    return res
  })
  dispatch({ type: 'fetchProperties', value: 0, data: properties })

  dispatch({type: 'setLoadingProperties', value: false})

  dispatch({type: 'setSearchSpinner', value: false})
}

export const retrieveProperties = ( params ) => {
  return getProperties( params ).then( response => {
    return response;
  })
}

export const setPropertiesFiltersData = ( val = []) => ({ type: 'setPropertiesFiltersData', value: val })

export const fetchPropertiesFiltersAction = ( params ) => async ( dispatch )  => {
  const propertiesFilters = await retrievePropertiesFilters( params ).then( res => {
    return res
  })
  dispatch({ type: 'fetchFilters', value: 0, data: propertiesFilters })
}

export const retrievePropertiesFilters = ( params ) => {
  return getPropertiesFilters( params ).then( response => {
    return response;
  })
}

export const countPropertiesForFiltersAction = ( params, propertiesParams, propertiesFiltersData ) => async ( dispatch )  => {
  const propertiesFilters = await countPropertiesForFilters( params, propertiesParams, propertiesFiltersData ).then( res => {
    return res
  })
  dispatch({ type: 'countPropertiesForFilters', value: 0, data: propertiesFilters })
}

export const countPropertiesForFilters = ( params, propertiesParams, propertiesFiltersData ) => {
  return getCountPropertiesForFilters( params, propertiesParams, propertiesFiltersData ).then( response => {
    return response;
  })
}

export const fetchPropertyAvailability = (propertyId, checkin, checkout) => async( dispatch ) => {
  let response = await getPropertyAvailability(propertyId, checkin, checkout)

  dispatch({ type: "updatePropertyAvailability", data: response })
}

export const fetchPropertyPrice = (property_id, page_id, checkin, checkout, adults, kids, priceNeedUpdate) => async ( dispath ) => {
  dispath({type: "updatePriceNeedUpdate", value: false})
  dispath({type: "updatePriceLoading", value: true})
  let response = await getPropertyPrice(property_id, page_id, checkin, checkout, adults, kids)

  if (response.success == 1) {
    dispath({type: "updatePriceInfo", value: response.pricing.price.price})
    dispath({type: "updateFinalPriceInfo", value: response.pricing.finalPrice.price})
    dispath({type: "updateDiscountInfo", value: response.pricing.dicountValue.price})
    if (response.pricing.securityDeposit != undefined) {
      dispath({type: "updateSecurityDeposit", value: response.pricing.securityDeposit.price})
    }
    dispath({type: "updateHasDiscountInfo", value: response.pricing.hasDiscount})
    dispath({type: "updateAccommondationLength", value: response.pricing.accommondationLength})
    dispath({type: "updatePropertyInitialAvailability", value: response.pricing.isAvailable})
  }

  if(priceNeedUpdate){
    // dispath({type: "updateDateRangeValid", value: false})
  }

  console.log("forDebug", "price", response)
  if(response.success){

  }


  // dispath({type: "updateDateRangeValid", value: true})
  dispath({type: "updatePriceLoading", value: false})
}

export const fetchPropertySpaces = (property_id) => async ( dispatch ) => {
  dispatch({type: "updateLoading", data: true})

  let areas = await getPropertyAreas(property_id, 'excludeTypes=36')

  dispatch({type: "updatePools", data: areas['pools']})
  dispatch({type: "updateSpaces", data: areas['spaces']})
  dispatch({type: "updateBedrooms", data: areas['bedrooms']})

  dispatch({type: "updateLoading", data: false})
}

export const fetchPropertyPool = (pageId, property_id, pool_id) => async ( dispatch ) => {

  let poolData = null;

  dispatch({type: "updateModalData", data: null, isValid: false})

  let pools = await getPropertyPools(pageId, property_id, 'pool_id='+pool_id)
  if(pools.pools!=undefined && pools.pools!=null && pools.pools.length>0){
    poolData = pools.pools[0]
  }

  dispatch({type: "updateModalData", data: poolData, isValid: true})
}

export const fetchPropertySpace = (pageId, property_id, space_id) => async ( dispatch ) => {

  let spaceData = null;

  dispatch({type: "updateModalData", data: null, isValid: false})

  let spaces = await getPropertySpaces(pageId, property_id, 'space_id='+space_id)
  if(spaces.spaces!=undefined && spaces.spaces!=null && spaces.spaces.length>0){
    spaceData = spaces.spaces[0]
  }

  dispatch({type: "updateModalData", data: spaceData, isValid: true})
}

export const fetchPropertyBedrooms = (pageId, property_id) => async ( dispatch ) => {

  let bedroomsData = null;

  dispatch({type: "updateModalBedrooms", data: null, isValid: false})

  let bedrooms = await getPropertyBedrooms(pageId, property_id)
  if(bedrooms.bedrooms!=undefined && bedrooms.bedrooms!=null && bedrooms.bedrooms.length>0){
    bedroomsData = bedrooms.bedrooms
  }

  dispatch({type: "updateModalBedrooms", data: bedroomsData, isValid: true})
}

export const fetchPropertyBedroomById = (pageId, property_id, bedroom_id) => async ( dispatch ) => {

  let bedroomsData = null;

  dispatch({type: "updateModalSingleBedroom", data: null, isValid: false})

  let bedrooms = await getPropertyBedroomById(pageId, property_id, bedroom_id)
  if(bedrooms.bedrooms!=undefined && bedrooms.bedrooms!=null && bedrooms.bedrooms.length>0){
    bedroomsData = bedrooms.bedrooms[0]
  }

  dispatch({type: "updateModalSingleBedroom", data: bedroomsData, isValid: true})
}